import React from "react";
import { dAppName } from "config";
import withPageTitle from "./components/PageTitle";
import Home from "./pages/Home";
import MyApes from "./pages/MyApes";
import DAO from "./pages/DAO";
import Staking from "./pages/Staking";

export const routeNames = {
  home: "/",
  dao: "/dao",
  staking: "/staking",
  wallet: "/wallet",
  unlock: "/unlock",
  ledger: "/ledger",
  walletconnect: "/walletconnect",
};

const routes: any[] = [
  {
    path: "/",
    title: "Home",
    component: Home,
  },
  {
    path: "/dao",
    title: "DAO",
    component: DAO,
  },
  {
    path: "/staking",
    title: "Staking",
    component: Staking,
  },
  {
    path: "/wallet",
    title: "Wallet",
    component: MyApes,
    authenticatedRoute: true,
  },
];

const wrappedRoutes = () =>
  routes.map((route) => {
    const title = route.title
      ? `${route.title} • Elrond ${dAppName}`
      : `Elrond ${dAppName}`;
    return {
      path: route.path,
      authenticatedRoute: Boolean(route.authenticatedRoute),
      component: withPageTitle(
        title,
        route.component,
      ) as any as React.ComponentClass<any, any>,
    };
  });

export default wrappedRoutes();
