import { Button, Form, Modal } from "react-bootstrap";
import React, { useState } from "react";
import "./Modal.scss";
import { getTokenMediaUrlByNonce, isNonceAuthorizedForPool } from "../../config";
import { getRemainingTimeLabelFromSeconds } from "../../helpers/dateHelper";

const StakeModal = ({
  showModal = false,
  triggerHideModal = new Function(),
  triggerStakeApes = new Function(),
  triggerUnstakeApes = new Function(),
  ownerNFTs = [],
  poolInfo = {
    id: 0,
    enabled: false,
    lockTime: 0,
    userInfo: {
      stakedNonce: []
    }
  }
}) => {

  const now = parseInt("" + Date.now() / 1000);
  const [countApeToStake, setCountApeToStake] = useState(0);
  const [countApeToUnstake, setCountApeToUnstake] = useState(0);

  // To stake
  const [walletApe, setWalletApe] = useState([] as any[]);

  // To unstake
  const [stakedApe, setStakedApe] = useState([] as any[]);

    const onModalExit = () => {
        resetData();
        triggerHideModal();
    }

    const onUnstakeClick = (item: any) => {
        // This ensures that even if we click, it doesn't select if NFT is still locked up
        if (now <= (item.nft.stakedTimestamp + poolInfo.lockTime)) {
            return;
        }

        const index = stakedApe.findIndex(sApe => sApe.id === item.id);

        if (-1 !== index) {
            item.checked = !item.checked;
            stakedApe[index] = item;
            setStakedApe(stakedApe);
            setCountApeToUnstake(stakedApe.filter(ape => ape.checked).length);
        }
    }

  const onStakeClick = (item: any) => {
    const index = walletApe.findIndex(wApe => wApe.id === item.id);

    if (-1 !== index) {
      item.checked = !item.checked;
      walletApe[index] = item;
      setWalletApe(walletApe);
      setCountApeToStake(walletApe.filter(ape => ape.checked).length);
      console.log(item);
    }
  };

  const onStakeClickBtn = () => {
    const apesNonceList = walletApe.filter(ape => ape.checked).map(ape => ape.nft.nonce);
    resetData();
    triggerStakeApes(apesNonceList);
  };

  const onUnstakeClickBtn = () => {
    const apesNonceList = stakedApe.filter(ape => ape.checked).map(ape => ape.nft.nonce);
    resetData();
    triggerUnstakeApes(apesNonceList);
  };

  const resetData = () => {
    setWalletApe([]);
    setStakedApe([]);
    setCountApeToUnstake(0);
  };

  React.useEffect(() => {
    if (showModal) {
      let wApes = [] as any[];
      let sApes = [] as any[];

      if (ownerNFTs && ownerNFTs.length > 0) {
        wApes = ownerNFTs.filter((nft: any) => poolInfo.enabled && isNonceAuthorizedForPool(poolInfo.id, nft.nonce))
          .map((item: any) => {
            return {
              id: item.nonce,
              nft: {
                nonce: item.nonce,
                url: getTokenMediaUrlByNonce(item.nonce)
              },
              checked: false
            }
          });
      }

      if (poolInfo.userInfo.stakedNonce.length > 0) {
        sApes = poolInfo.userInfo.stakedNonce.map((item: any) => {
          return {
            id: item,
            nft: {
              nonce: item.nonce,
              stakedTimestamp: item.stakedTimestamp,
              url: getTokenMediaUrlByNonce(item.nonce)
            },
            checked: false
          }
        })
      }

      setWalletApe(wApes);
      setStakedApe(sApes);
    }
  }, [showModal, ownerNFTs, poolInfo]);

  const RenderStakingRemainingTimeCountdown = (item: any) => {
    const end = (item.nft.stakedTimestamp + poolInfo.lockTime);
    if (now > end) {
      return (
        <div className="form-check-container">
          <Form.Check
            type="checkbox"
            checked={item.checked}
            value={item.checked}
          />
        </div>
      );
    } else {
      return (
        <div className="unlock-time">
          <div className="unlock-time-container font-family-content">
            <span>Unlocked in</span>
            <span>{getRemainingTimeLabelFromSeconds(end - now, false)}</span>
          </div>
        </div>
      );
    }
  }

  return (
    <Modal show={showModal} onHide={() => onModalExit()} className="stake-modal">
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title className="font-family-title">
            Stake / Unstake
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="ape-view">
            <div className="wallet-apes">
              <div className="section-name font-family-content">Wallet ({walletApe.length})</div>
              <div className="apes-container">
                {0 === walletApe.length && (
                  <div className="font-family-content">No eligible apes</div>
                )}
                {walletApe && walletApe.length > 0 && walletApe.map((item: any) => (
                  <div key={item.nft.nonce} className="preview-ape-container" onClick={() => onStakeClick(item)}>
                    <div className={"wallet-ape " + (item.checked ? "selected-wallet-ape" : "")}>
                      <img
                        className="wallet-ape-img"
                        src={getTokenMediaUrlByNonce(item.nft.nonce)}
                        alt={`Ape ${item.nft.nonce-1}`}
                      />
                      <div
                        className="ape-nonce font-family-content">
                        #{item.nft.nonce-1}
                      </div>
                      <div className="form-check-container">
                        <Form.Check
                          type="checkbox"
                          checked={item.checked}
                          value={item.checked}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="action-btn-modal-container">
                <Button
                  className="modal-action-btn font-family-content"
                  disabled={0 === countApeToStake}
                  onClick={() => onStakeClickBtn()}
                >
                  Stake { countApeToStake > 0 ? "(" + countApeToStake + ")" : "" }
                </Button>
              </div>
            </div>
            <div className="vertical-bar"/>
            <div className="wallet-apes">
              <div className="section-name font-family-content">
                Staked {stakedApe.length > 0 ? "(" + stakedApe.length + ")" : "" }
              </div>
              <div className="apes-container">
                {0 === stakedApe.length && (
                  <div className="font-family-content">No staked apes</div>
                )}
                {stakedApe.length > 0 && stakedApe.map((item: any) => (
                  <div key={item.nft.nonce} className="preview-ape-container" onClick={() => onUnstakeClick(item)}>
                    <div className={"wallet-ape " + (item.checked ? "selected-wallet-ape" : "")}>
                      <img
                          className="wallet-ape-img"
                          src={item.nft.url}
                          alt={`Ape ${item.nft.nonce}`}
                      />
                      <div
                          className="ape-nonce font-family-content">
                        #{item.nft.nonce-1}
                      </div>
                      {RenderStakingRemainingTimeCountdown(item)}
                    </div>
                  </div>
                ))}
              </div>
              {0 !== stakedApe.length && (
                <div className="action-btn-modal-container">
                  <Button
                    className="modal-action-btn font-family-content"
                    disabled={0 === countApeToUnstake}
                    onClick={() => onUnstakeClickBtn()}
                  >
                    Unstake { countApeToUnstake > 0 ? "(" + countApeToUnstake + ")" : "" }
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

export default StakeModal;
