import React from "react";
import {
  NavItem,
  Nav,
  NavDropdown,
  Container,
  Navbar,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ElrondApesLogo from "../../../assets/img/elrondapes-logo.gif";
import MaiarLogo from "../../../assets/img/maiar_logo.png";
import "./NavigationNavbar.scss";
import { network } from "../../../config";
import {cropErdAddress} from "../../../helpers/stringHelper";
import {logout} from "@elrondnetwork/dapp-core/utils";
import {useGetAccountInfo} from "@elrondnetwork/dapp-core/hooks";

const NavigationNavbar = () => {
  const { address } = useGetAccountInfo();

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };

  return (
    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Container>
        <Navbar.Brand>
          <Link className="d-flex navbar-brand mr-0" to={"/"}>
            <img className="logo" src={ElrondApesLogo} alt="Elrond Apes logo" />
            {network.name !== "Mainnet" && (
                <div className="env">{network.name}</div>
            )}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <NavDropdown title="Collections" id="collasible-nav-dropdown">
              <NavDropdown.Item href="https://trust.market/collection/EAPES-8f3c1f">
                TrustMarket
              </NavDropdown.Item>
              <NavDropdown.Item href="https://deadrare.io/collection/EAPES-8f3c1f">
                Deadrare
              </NavDropdown.Item>
            </NavDropdown>
            <NavItem>
              <Link to={"/dao"}>DAO</Link>
            </NavItem>
            <NavItem>
              <Link to={"/staking"}>Staking</Link>
            </NavItem>
            <Nav.Link>
              {address && (
                  <NavItem>
                    <button className="menu-btn claim-btn">
                      <img className="maiar-logo" src={MaiarLogo} alt="Maiar Logo" />
                      <span onClick={() => window.open(`${network.explorerAddress}accounts/${address}`, '_blank')}>
                        {cropErdAddress(address)}
                      </span>
                      <span className="logout" onClick={handleLogout}>
                  X
                </span>
                    </button>
                  </NavItem>
              )}
              {!address && (
                  <NavItem>
                    <Link to={"/unlock"}>
                      <button className="menu-btn claim-btn">CONNECT</button>
                    </Link>
                  </NavItem>
              )}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationNavbar;
