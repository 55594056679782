const SPECIAL_NONCE_LIST = require("./assets/json/special.json"); // Clown nose
const RANK_1_1000_NONCE_LIST = require("./assets/json/1-1000.json");
const RANK_1001_2500_NONCE_LIST = require("./assets/json/1001-2500.json");
const RANK_2501_4500_NONCE_LIST = require("./assets/json/2501-4500.json");
const RANK_4501_7000_NONCE_LIST = require("./assets/json/4501-7000.json");
const RANK_7001_10000_NONCE_LIST = require("./assets/json/7001-10000.json");

export const dAppName = "Apes";
export const decimals = 2;
export const denomination = 18;
export const gasPrice = 1000000000;
export const version = 1;
export const gasLimit = 50000;
export const gasPerDataByte = 1500;

export const walletConnectBridge = "https://bridge.walletconnect.org";
export const walletConnectDeepLink =
    "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet.dev&link=https://maiar.com/";

export const contractAddress =
    "erd1qqqqqqqqqqqqqpgq7gma4udjeh3mwuq4lmk7wccgh0tenxu6yv5qc2csfy";

export const governanceAddress =
    "erd1qqqqqqqqqqqqqpgq69hy6nujvt6lhxmtxqzpvw97hplm2vkl386q2quya0";

export const MIN_CHARACTERS_PROPOSAL = 180;
export const MAX_CHARACTERS_PROPOSAL = 280;

export const stakingPools = [
  {
    id: 1,
    active: true,
    name: "Prime Pool",
    description:
        "Prime Apes may stake in this pool. The allocation for distribution is 15% for this pool.",
    address: "erd1qqqqqqqqqqqqqpgq50t0ma43rr3895lq7dhgm786kdvfwlyq386qafuqhu",
  },
  {
    id: 4,
    active: true,
    name: "Special Pipe Pool",
    description:
        "Special Trait Pool will be Pipe Mouth. Please stake your ape in this pool. The allocation for distribution is 5% for this pool.",
    address: "erd1qqqqqqqqqqqqqpgqwh0ck84phy7tm8z30ctmhedrdzfz5q9q386qgafqca",
  },
  {
    id: 6,
    active: true,
    name: "Rank 1-1000 Pool",
    description:
        "Rank 1 to 1000, may stake in this pool. The allocation for distribution is 10% for this pool.",
    address: "erd1qqqqqqqqqqqqqpgqnk6e6mrs0kdsusdt7hshpql6y4pcczwu386qjzhk63",
  },
  {
    id: 7,
    active: true,
    name: "Rank 1001-2500 Pool",
    description:
        "Rank 1001 to 2500, may stake in this pool. The allocation for distribution is 15% for this pool.",
    address: "erd1qqqqqqqqqqqqqpgq67d2pnfg6f6td7lxq8x0rtzylhkt32gs386qh8vtv3",
  },
  {
    id: 8,
    active: true,
    name: "Rank 2501-4500 Pool",
    description:
        "Rank 2501 to 4500, may stake in this pool. The allocation for distribution is 18% for this pool.",
    address: "erd1qqqqqqqqqqqqqpgqz4wny33hhrnzsy4d9x3xzg749xpwe62f386qkwyw3x",
  },
  {
    id: 9,
    active: true,
    name: "Rank 4501-7000 Pool",
    description:
        "Rank 4501 to 7000, may stake in this pool. The allocation for distribution is 20% for this pool.",
    address: "erd1qqqqqqqqqqqqqpgq5ycqhnggkjvqal259pmzauvg6rm2f232386qfsk5lc",
  },
  {
    id: 10,
    active: true,
    name: "Rank 7001-10000 Pool",
    description:
        "Rank 7001 to 10000, may stake in this pool. The allocation for distribution is 17% for this pool.",
    address: "erd1qqqqqqqqqqqqqpgqjengc6lmwh2lete065ndjczsf3dns7y3386qlteynl",
  },
  {
    id: 2,
    active: false,
    name: "Tribe Pool (old)",
    description:
        "The pool is now closed, please unstake your ape and stake them into the ranking pool to carry on earning rewards.",
    address: "erd1qqqqqqqqqqqqqpgqke7n6rljgcffhc7c5lwgsd9fxh2zkev4386q28vr73",
  },
  {
    id: 11,
    active: false,
    name: "Alien Headband (old)",
    description:
        "The pool is now closed, please unstake your ape and stake them into the ranking pool to carry on earning rewards.",
    address: "erd1qqqqqqqqqqqqqpgqgz3vsy7c3w5hw8vsmlzcchkyxmmg8q4r386qqs3ksl",
  },
  {
    id: 12,
    active: true,
    name: "Lollipop Pool (old)",
    description:
        "The pool is now closed, please unstake your ape and stake them into the ranking pool to carry on earning rewards.",
    address: "erd1qqqqqqqqqqqqqpgqmylu4uea0kce4pl5xez9d7kq5kwztykx386q664fe3",
  },
  {
    id: 13,
    active: true,
    name: "Clown Nose Pool (old-",
    description:
        "The pool is now closed, please unstake your ape and stake them into the ranking pool to carry on earning rewards.",
    address: "erd1qqqqqqqqqqqqqpgqwkdk7jy29r3nr2x9tzu0ywwr6tdfv7e8386q5y7ylf",
  },
];

export const isNonceAuthorizedForPool = (poolId: number, nonce: number): boolean => {
  if (poolId > 0 && poolId <= 12 && nonce > 0 && nonce <= 10000) {
    // Prime NFT should have nonce between 1 & 201
    const isPrime = nonce >= 1 && nonce <= 201;
    if (poolId === 1) {
      // Prime Pool
      return isPrime;
    } else if (poolId === 4) {
      // 3D Glasses
      return !isPrime && SPECIAL_NONCE_LIST.includes(nonce);
    } else if (poolId === 6) {
      return !isPrime && RANK_1_1000_NONCE_LIST.includes(nonce);
    } else if (poolId === 7) {
      return !isPrime && RANK_1001_2500_NONCE_LIST.includes(nonce);
    } else if (poolId === 8) {
      return !isPrime && RANK_2501_4500_NONCE_LIST.includes(nonce);
    } else if (poolId === 9) {
      return !isPrime && RANK_4501_7000_NONCE_LIST.includes(nonce);
    } else if (poolId === 10) {
      return !isPrime && RANK_7001_10000_NONCE_LIST.includes(nonce);
    }
  }
  return false;
};

export const network = {
  id: "mainnet",
  name: "Mainnet",
  egldLabel: "EGLD",
  walletAddress: "https://wallet.elrond.com",
  apiAddress: "https://api.elrond.com",
  gatewayAddress: "https://gateway.elrond.com",
  explorerAddress: "https://explorer.elrond.com/",
};

export const COLLECTION_IDENTIFIER = "EAPES-8f3c1f";
export const distributionAddress = "erd1qqqqqqqqqqqqqpgqhgq8dcd0t4q3rswvetwa25w092lvqfhv386qe6v45g";

// This method is used for displaying mainnet ape images from the nonce
export const getTokenMediaUrlByNonce = (nonce: number) => {
  const hash = nonce < 5000
      ? "QmRP9FVznm1xxa3FEsDENXhafRDaRWY6LKQdg8GygHNHKw"
      : "QmUBFTnxZpaM7xrJ62Z9kNi3dfQwEWPQhthsnXdLEjJhDb";
  return `https://media.elrond.com/nfts/asset/${hash}/${nonce - 1}.png`;
};
// http://localhost:3001
// https://ms.elrondapes.com
export const microserviceAddress = "https://elrond-apes-ms-tmp-6jzrl.ondigitalocean.app";
