import React from "react";

import "./StatCard.scss";
import {Button} from "react-bootstrap";

const StatCard = ({
  amount = 0,
  currency = "",
  claimSingle = new Function()
}) => {
  return (
    <div className="stat-card-container">
      <div className="reward-container">
        <div className="currency font-family-title">{currency}</div>
        <Button
          className="single-claim-btn font-family-content"
          onClick={() => claimSingle()}
        >
          Claim {amount}
        </Button>
      </div>
    </div>
  );
};

export default StatCard;
