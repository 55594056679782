import React from "react";
import routes, { routeNames } from "routes";
import Footer from "./Footer";
import NavigationNavbar from "./NavigationNavbar";
import {AuthenticatedRoutesWrapper} from "@elrondnetwork/dapp-core/wrappers";
import {useLocation} from "react-router-dom";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { search } = useLocation();
  return (
    <div className="d-flex flex-column flex-fill wrapper">
      <NavigationNavbar />
      <main className="d-flex flex-column flex-grow-1">
          <AuthenticatedRoutesWrapper
              routes={routes}
              unlockRoute={`${routeNames.unlock}${search}`}
          >
              {children}
          </AuthenticatedRoutesWrapper>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
