import * as React from "react";

import {
  COLLECTION_IDENTIFIER, distributionAddress,
  microserviceAddress,
  stakingPools
} from "../../config";
import "./Staking.scss";
import { useState } from "react";
import StakingPool from "../../components/StakingPool";
import StakeModal from "../../components/Modal/StakeModal";
import { routeNames } from "../../routes";
import {
  buildClaimAllRawTx,
  buildClaimSingleTokenRawTx,
  buildStakeNftRawTx,
  buildUnstakeNftsRawTx
} from "../../helpers/transactionBuilder";
import { getOwnerNFTRequest } from "../../helpers/apiRequests";
import StatCard from "../../components/StatCard";
import axios from "axios";
import { weiToNumber } from "../../helpers/numberHelper";
import { Button, Spinner } from "react-bootstrap";
import { useCookies } from "react-cookie";
import {useGetAccountInfo} from "@elrondnetwork/dapp-core/hooks";
import {sendTransactions} from "@elrondnetwork/dapp-core/services";
import {refreshAccount} from "@elrondnetwork/dapp-core/utils";

const Staking = () => {

  const { address } = useGetAccountInfo();
  const [poolInfoArray, setPoolInfoArray] = useState([] as any);
  const [currentPoolInfo, setCurrentPoolInfo] = useState(undefined as any);
  const [userStakingRewards, setUserStakingRewards] = useState([] as any);
  const [ownerNFTs, setOwnerNFTs] = useState([] as any);

  const [showStakeModal, setShowStakeModal] = useState(false);
  const [showMainLoader, setShowMainLoader] = useState(false);

  // Cookies
  const [, setCookie] = useCookies(['canSubmit', 'votingPower']);

  const /*transactionSessionId*/ [, setTransactionSessionId] = React.useState<
      string | null
      >(null);

  const claimAllTx = async (transaction: any) => {
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Claim',
        errorMessage: 'An error has occurred during Claim',
        successMessage: 'Claim transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
    await sendTransactions({
      transaction: transaction,
      callbackRoute: routeNames.staking,
    });
  };

  const claimSingleTx = async (transaction: any) => {
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Claim',
        errorMessage: 'An error has occurred during Claim',
        successMessage: 'Claim transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
    await sendTransactions({
      transaction: transaction,
      callbackRoute: routeNames.staking,
    });
  };

  const loadPoolsInf = async () => {
    setPoolInfoArray([]);
    setShowMainLoader(true);

    for (let i = 0; i < stakingPools.length; i++) {
      const currentSP = stakingPools[i];
      const poolInfo = await getPoolInformation(currentSP.address);

      if (null !== poolInfo) {
        poolInfo["id"] = currentSP.id;
        poolInfo["address"] = currentSP.address;
        poolInfo["name"] = currentSP.name;
        poolInfo["description"] = currentSP.description;

        if (address) {
          const poolUserInfo = await getPoolUserInformation(currentSP.address);
          if (null !== poolUserInfo) {
            poolInfo["userInfo"] = poolUserInfo;
          }
        }
        setPoolInfoArray((pArray: any) => [...pArray, poolInfo]);
      }
    }
    setShowMainLoader(false);
  };

  const getPoolInformation = async (poolAddress: any) => {
    const url = `${microserviceAddress}/staking/pools/${poolAddress}`;
    const result = await axios.get(url, { timeout: 10000 });
    if (200 === result.status) {
      return result.data.poolInfo;
    }
    console.error(result);
    return null;
  };

  const getPoolUserInformation = async (poolAddress: string) => {
    const url = `${microserviceAddress}/staking/pools/${poolAddress}/users/${address}`;
    const result = await axios.get(url, { timeout: 10000 });
    if (200 === result.status) {
      return result.data.poolUserInfo;
    }
    console.error(result);
    return null;
  };

  React.useEffect(() => {
    loadPoolsInf()
  }, []);

  // Get owner NFTs
  React.useEffect(() => {
    if (address) {
      const getOwnerNFTs = async () => {
        getOwnerNFTRequest(address)
          .then((data: any) => {
            if (data.status === 200 && data.data) {
              setOwnerNFTs(data.data);
            }
          })
          .catch((error: any) => console.error(error))
      };
      getOwnerNFTs();
    }
  }, [address]);

  // load user rewards
  React.useEffect(() => {
    if (address) {
      const loadUserRewards = async () => {
        const url = `${microserviceAddress}/distribution/${distributionAddress}/user-rewards/${address}`;
        const result = await axios.get(url, { timeout: 10000 });
        if (200 === result.status && result.data && result.data.pendingRewards) {
          setUserStakingRewards(result.data.pendingRewards);
        } else {
          setUserStakingRewards([]);
        }
      };
      loadUserRewards();
    }
  }, [address]);

  const triggerStakeOpenModal = (poolInfo: any) => {
    setCurrentPoolInfo(poolInfo);
    setShowStakeModal(true);
  };

  const stake = async (nonceList: number[]) => {
    const poolInfo = currentPoolInfo;
    setCurrentPoolInfo(undefined);
    setShowStakeModal(false);

    // Invalidate cookies
    setCookie("canSubmit", null, {path: "/", expires: new Date()});
    setCookie("votingPower", null, {path: "/", expires: new Date()});

    await refreshAccount();

    await sendTransactions({
      transactions: buildStakeNftRawTx(address, poolInfo.address, COLLECTION_IDENTIFIER, nonceList),
      transactionsDisplayInfo: {
        processingMessage: 'Processing Claim',
        errorMessage: 'An error has occurred during Claim',
        successMessage: 'Claim transaction successful'
      },
      redirectAfterSign: false
    });
  };

  const unstake = async (nonceList: number[]) => {
    const poolInfo = currentPoolInfo;
    setCurrentPoolInfo(undefined);
    setShowStakeModal(false);

    // Invalidate cookies
    setCookie("canSubmit", null, {path: "/", expires: new Date()});
    setCookie("votingPower", null, {path: "/", expires: new Date()});


    send(buildUnstakeNftsRawTx(poolInfo.address, nonceList));

    await sendTransactions({
      transactions: buildUnstakeNftsRawTx(poolInfo.address, nonceList),
      transactionsDisplayInfo: {
        processingMessage: 'Processing Claim',
        errorMessage: 'An error has occurred during Claim',
        successMessage: 'Claim transaction successful'
      },
      redirectAfterSign: false
    });
  };

  const send = (transaction: any) => {
    sendTransactions({
      transaction: transaction,
      callbackRoute: routeNames.staking,
    });
  };

  return (
    <div className="d-flex align-items-center container">
      <div className="row w-100">
        <div className="staking-container">
          {stakingPools.length === 0 && (
            <div className="no-ape-msg font-family-title fs-30">
              No Staking Pools yet.
            </div>
          )}

          {showMainLoader && (
              <div className="spinner-container">
                <div className="spinner-and-text-container">
                  <Spinner animation="border" role="status" className="spinner" />
                </div>
              </div>
          )}

          <div className="staking-pools-container">
            {poolInfoArray.length > 0 &&
              poolInfoArray.map((poolInfo: any) => (
                <StakingPool
                  key={poolInfo.id}
                  poolInfo={poolInfo}
                  stakeTriggerModal={() => triggerStakeOpenModal(poolInfo)}
                  btnEnabled={address}
                />
              ))}
          </div>
        </div>


        <div className="staking-reward-container">
          <h2 className="title font-family-title">Staking rewards</h2>

          {userStakingRewards.length === 0 && (
            <div className="no-rewards font-family-content">
              No pending rewards yet
            </div>
          )}

          {userStakingRewards.length > 1 && (
            <Button
              className="claim-btn font-family-content"
              onClick={() => claimAllTx(buildClaimAllRawTx(distributionAddress))}
            >
              Claim all
            </Button>
          )}

          {userStakingRewards.length > 0 && (
            <div className="staking-reward-list">
              {userStakingRewards.map((reward: any) => (
                <StatCard
                  key={ reward.token + '-' + reward.nonce }
                  amount={weiToNumber(reward.amount)}
                  currency={ reward.nonce > 1
                    ? reward.token + '-' + (reward.nonce.toString(16).length > 2 ? reward.nonce.toString(16) : "0" + reward.nonce.toString(16))
                    : reward.token }
                  claimSingle={() => claimSingleTx(buildClaimSingleTokenRawTx(distributionAddress, reward))}
                />
              ))}
            </div>
          )}
        </div>
      </div>


      <StakeModal
        ownerNFTs={ownerNFTs}
        showModal={showStakeModal}
        triggerHideModal={() => setShowStakeModal(false)}
        triggerStakeApes={stake}
        triggerUnstakeApes={unstake}
        poolInfo={currentPoolInfo}
      />
    </div>
  );
};

export default Staking;
