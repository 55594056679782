import React from "react";
import "./Footer.scss";
import DiscordLogo from "../../../assets/img/icons/discord.png";
import TelegramLogo from "../../../assets/img/icons/telegram.png";
import TwitterLogo from "../../../assets/img/icons/twitter.png";

const Footer = () => {
  return (
    <footer className="text-center mt-2">
      <div className="social-icons-container">
        <div className="social-icon discord-icon">
          <a href="https://discord.com/invite/elrondapes">
            <img src={DiscordLogo} alt="Discord Social" />
          </a>
        </div>

        <div className="social-icon twitter-icon">
          <a href="https://twitter.com/ElrondApes">
            <img src={TwitterLogo} alt="Twitter Social" />
          </a>
        </div>

        <div className="social-icon telegram-icon">
          <a href="https://t.me/ElrondAPEs">
            <img src={TelegramLogo} alt="Telegram Social" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
